var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBL0003231" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBL0003232", icon: "add" },
                            on: { btnClicked: _vm.addParent },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              disabled: !_vm.saveable,
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.data,
                              mappingType: _vm.saveType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveData,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable && _vm.dataeditable,
                        required: true,
                        label: "LBL0003230",
                        name: "processName",
                      },
                      model: {
                        value: _vm.data.processName,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "processName", $$v)
                        },
                        expression: "data.processName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable:
                          _vm.editable && _vm.dataeditable && !_vm.updateMode,
                        required: true,
                        label: "공정코드",
                        name: "processCd",
                      },
                      model: {
                        value: _vm.data.processCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "processCd", $$v)
                        },
                        expression: "data.processCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: true,
                        editable: _vm.editable && _vm.dataeditable,
                        label: "LBLPLANT",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.data.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "plantCd", $$v)
                        },
                        expression: "data.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable && _vm.dataeditable,
                        required: true,
                        label: "LBLSORTORDER",
                        name: "orderNo",
                      },
                      model: {
                        value: _vm.data.orderNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "orderNo", $$v)
                        },
                        expression: "data.orderNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable && _vm.dataeditable,
                        isFlag: true,
                        label: "LBL0003234",
                        name: "psmFlag",
                      },
                      model: {
                        value: _vm.data.psmFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "psmFlag", $$v)
                        },
                        expression: "data.psmFlag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable && _vm.dataeditable,
                        isFlag: true,
                        label: "LBLUSEFLAG",
                        name: "useFlag",
                      },
                      model: {
                        value: _vm.data.useFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "useFlag", $$v)
                        },
                        expression: "data.useFlag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-5" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable && _vm.dataeditable,
                        label: "LBL0003235",
                        name: "mainMaterial",
                      },
                      model: {
                        value: _vm.data.mainMaterial,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "mainMaterial", $$v)
                        },
                        expression: "data.mainMaterial",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-5" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable && _vm.dataeditable,
                        label: "LBL0003236",
                        name: "productName",
                      },
                      model: {
                        value: _vm.data.productName,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "productName", $$v)
                        },
                        expression: "data.productName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable && _vm.dataeditable,
                        label: "LBL0003237",
                        name: "workerCnt",
                        suffix: _vm.$label("LBL0003239"),
                        type: "number",
                      },
                      model: {
                        value: _vm.data.workerCnt,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "workerCnt", $$v)
                        },
                        expression: "data.workerCnt",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        counter: true,
                        editable: _vm.editable && _vm.dataeditable,
                        label: "LBL0003238",
                        name: "processDesc",
                        rows: 2,
                      },
                      model: {
                        value: _vm.data.processDesc,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "processDesc", $$v)
                        },
                        expression: "data.processDesc",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfoUp,
                        editable:
                          _vm.editable && _vm.attachable && _vm.dataeditable,
                        label: "LBL0003240",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        attachInfo: _vm.attachInfo3,
                        editable:
                          _vm.editable && _vm.attachable && _vm.dataeditable,
                        label: "LBL0003241",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }